import React, { Component } from 'react'

export default class Logo extends Component {
  render() {
    return (
      <svg
        className="Logo"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 974 214.4"
      >
        <rect fill="#DC1E0F" width="974" height="214.4" />
        <path
          fill="#FFFFFF"
          d="M110.7,122.3h-41l-2.2,2.2v56.1l-2.2,2.2H39.5l-2.2-2.2V33.9l2.2-2.2h71.2c30.2,0,51.8,15.1,51.8,45.3
	S140.8,122.3,110.7,122.3z M110.7,57.6h-41l-2.2,2.2v34.5l2.2,2.2h41c12.9,0,21.6-4.3,21.6-19.4S123.6,57.6,110.7,57.6z
	 M276.7,120.2v2.2l28,36.7v21.6l-2.2,2.2h-12.9l-4.3-2.2l-43.1-56.1l-4.3-2.2h-21.6l-2.2,2.2v56.1l-2.2,2.2h-25.9l-2.2-2.2V33.9
	l2.2-2.2h71.2c30.2,0,51.8,15.1,51.8,45.3C309.1,100.8,294,115.8,276.7,120.2z M257.3,57.6h-41l-2.2,2.2v34.5l2.2,2.2h41
	c12.9,0,21.6-4.3,21.6-19.4S270.2,57.6,257.3,57.6z M451.4,182.7H432l-2.2-2.2l-10.8-28l-4.3-2.2h-53.9l-4.3,2.2l-10.8,28l-2.2,2.2
	h-19.4l-2.2-2.2V159l49.6-125.1l2.2-2.2h28l2.2,2.2L453.6,159v21.6L451.4,182.7z M388.9,70.6h-2.2l-19.4,51.8l2.2,2.2h36.7l2.2-2.2
	L388.9,70.6z M589.4,182.7h-15.1l-4.3-2.2l-47.4-58.2h-2.2l-15.1,15.1v43.1l-2.2,2.2h-25.9l-2.2-2.2V33.9l2.2-2.2h25.9l2.2,2.2v62.5
	h2.2L570,33.9l4.3-2.2h10.8l2.2,2.2v21.6l-43.1,43.1v2.2l47.4,58.2v21.6L589.4,182.7z M667.1,184.9c-43.1,0-62.5-21.6-62.5-49.6
	l2.2-2.2h25.9l2.2,2.2c0,15.1,8.6,23.7,34.5,23.7c19.4,0,30.2-6.5,30.2-19.4c0-15.1-8.6-19.4-38.8-23.7
	c-30.2-4.3-53.9-15.1-53.9-45.3c0-21.6,17.3-41,58.2-41s58.2,19.4,58.2,45.3L721,77h-25.9l-2.2-2.2c0-12.9-6.5-19.4-30.2-19.4
	c-19.4,0-25.9,6.5-25.9,15.1c0,12.9,8.6,17.3,38.8,21.6c30.2,4.3,53.9,17.3,53.9,47.4C729.6,163.3,712.4,184.9,667.1,184.9z
	 M783.6,182.7h-25.9l-2.2-2.2V33.9l2.2-2.2h25.9l2.2,2.2v146.7L783.6,182.7z M874.1,184.9c-43.1,0-62.5-21.6-62.5-49.6l2.2-2.2h25.9
	l2.2,2.2c0,15.1,8.6,23.7,34.5,23.7c19.4,0,30.2-6.5,30.2-19.4c0-15.1-8.6-19.4-38.8-23.7c-30.2-4.3-53.9-15.1-53.9-45.3
	c0-21.6,17.3-41,58.2-41s58.2,19.4,58.2,45.3L928,77h-25.9l-2.2-2.2c0-12.9-6.5-19.4-30.2-19.4c-19.4,0-25.9,6.5-25.9,15.1
	c0,12.9,8.6,17.3,38.8,21.6c30.2,4.3,53.9,17.3,53.9,47.4C936.7,163.3,919.4,184.9,874.1,184.9z"
        />
      </svg>
    )
  }
}
