import React from 'react'
import styled from 'styled-components'

const StyledSvgFlame = styled.svg`
  height: 1em;
  width: 1.6em; // ratio
  margin-left: rem-calc(5);
  display: inline-block;
  vertical-align: middle;
`

const SvgFlame = (props) => (
  <StyledSvgFlame
    xmlns="http://www.w3.org/2000/svg"
    width="48.8"
    height="30.2"
    viewBox="0 0 48.8 30.2"
    {...props}
  >
    <path
      d="M15.8 30c4.5-1.4 11.3-4.5 13.7-10.1-1.8 1.8-10.5 5.2-11.8 2.7-.4-.7.7-2.8 4.9-3.4 4.6-.6 10.6-1.3 13.2-7.1-6.7 4.6-8.9-1-17.2 3 1.9-3.7 5.7-5.1 10-5.2 4.5-.1 9.5.3 12.7-3.5-1.5 1-3.3 1-4.5.8-1-.2-5.8-1.7-8.5-1.7-9.9 0-16.4 5.8-18.2 13.6 0 0-2.4-8.3 7.7-12.9C8.3 6.8.4 10.9 3 24.3-2.8 16.6.2 8.9 9.3 4.7c3-1.6 5-2.3 6.3-4.4-.3 2.1-1.7 4.3 1.3 4.3 1.7.1 4.6-.4 8.8-1.8 3.2-1 7.7-4.2 13.5-2-3 .2-5.3 1.3-5 2.8.5 2.7 10.9.1 14.6 2.8-3.6-.4-9.5 3-10.3 6-.4 1.6 1.5 3.1 4.2 2.8-2.6 2.1-10.6 2.7-10.6 4.8 0 .9.9 1.9 3.4 2.1-3.2 1.8-5.2 1.2-6.8 3-2.3 2.6-6.9 6.1-12.9 4.9zm.4-2.6c-1 .2-3-.1-3.9-.8-.7-.7-1.1-2.8-.8-4-.6.6-3.4 1.1-4.6-.1-1.3-1.3-1.2-4-.6-6.1-.5.3-1.5 2.3-1.7 3.7-.2 1.8.1 3.8 2.2 5.4 3.6 2.7 4.5 3.3 6.1 3.1.9-.1 2.8-.8 3.3-1.2z"
      fill="#ffffff"
    />
  </StyledSvgFlame>
)

export default SvgFlame
